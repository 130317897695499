import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=0a634000&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=0a634000&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a634000",
  null
  
)


import {QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn})
/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src277794545/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a634000')) {
      api.createRecord('0a634000', component.options)
    } else {
      api.reload('0a634000', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=0a634000&scoped=true&", function () {
      api.rerender('0a634000', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/Index.vue"
export default component.exports